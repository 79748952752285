<div class="font-syne text-2xl font-semibold mb-10">Betingelser</div>

<div class="flex">
  <input
    [(ngModel)]="helvetiaAcceptConditions.acceptedTradingConditions"
    type="checkbox"
    class="shrink-0 border-gray-200 focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7] rounded"
    id="acceptedTradingConditions"
  />
  <label for="acceptedTradingConditions">
    <p
      [ngClass]="{
        'text-red-500': this.errorInTradingConditionsCheckbox
      }"
      class="ml-4 text-sm"
    >
      <span *ngIf="textDirectedToCustomer">Du</span>
      <span *ngIf="!textDirectedToCustomer">Kunden</span>
      accepterer
      <a href="assets/docs/Forsikringsbetingelser_helvetia.pdf" target="_blank"
        >handelsbetingelserne</a
      >
      og databehandlererklæringen og erkender, at de ikke er registreret i RKI
      samt ikke er blevet opsagt af nuværende forsikringsselskab
      <span class="text-red-500">*</span>
    </p></label
  >
</div>

<div class="flex mt-4">
  <input
    [(ngModel)]="helvetiaAcceptConditions.acceptedInfoIsCorrect"
    type="checkbox"
    class="shrink-0 border-gray-200 focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7] rounded"
    id="acceptedInfoIsCorrect"
  />
  <label for="acceptedInfoIsCorrect">
    <p
      [ngClass]="{
        'text-red-500': this.errorInInfoIsCorrecCheckbox
      }"
      class="ml-4 text-sm"
    >
      <span *ngIf="textDirectedToCustomer">Du</span>
      <span *ngIf="!textDirectedToCustomer">Kunden</span> erklærer hermed på tro
      og love, at de indtastede informationer er korrekte og erkender, at det er
      strafbart, hvis informationerne er falske eller ukorrekte
      <span class="text-red-500">*</span>
    </p></label
  >
</div>

<div class="flex mt-4">
  <input
    [(ngModel)]="helvetiaAcceptConditions.acceptedConsent"
    type="checkbox"
    class="shrink-0 border-gray-200 focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7] rounded"
    id="acceptedConsent"
  />
  <label for="acceptedConsent">
    <p
      [ngClass]="{
        'text-red-500': this.errorInConsentCheckbox
      }"
      class="ml-4 text-sm"
    >
      <span *ngIf="textDirectedToCustomer">Du</span>
      <span *ngIf="!textDirectedToCustomer">Kunden</span> samtykker til, at
      Helvetia må opsige deres nuværende forsikring, såfremt deres bil er
      forsikret i et andet selskab<span class="text-red-500">*</span>
    </p></label
  >
</div>

<div *ngIf="chargingStand">
  <div class="flex mt-4">
    <input
      [(ngModel)]="helvetiaAcceptConditions.acceptedChargingStandCustomer"
      type="checkbox"
      class="shrink-0 border-gray-200 focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7] rounded"
      id="acceptedChargingStandCustomer"
    />
    <label for="acceptedChargingStandCustomer">
      <p
        [ngClass]="{
          'text-red-500': this.errorInChargingStandCustomer
        }"
        class="ml-4 text-sm"
      >
        <span *ngIf="textDirectedToCustomer">Du</span>
        <span *ngIf="!textDirectedToCustomer">Kunden</span> samtykker til, at
        navn, adresse, telefonnummer og e-mail må videregives til Energi Fyn med
        henblik på at blive direkte kontaktet af Energi Fyn for tilbud på
        lade-abonnement mv.<span class="text-red-500">*</span>
      </p></label
    >
  </div>

  <div class="flex mt-4">
    <input
      [(ngModel)]="helvetiaAcceptConditions.acceptedChargingStandConsultant"
      type="checkbox"
      class="shrink-0 border-gray-200 focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7] rounded"
      id="acceptedChargingStandConsultant"
    />
    <label for="acceptedChargingStandConsultant">
      <p
        [ngClass]="{
          'text-red-500': this.errorInChargingStandConsultant
        }"
        class="ml-4 text-sm"
      >
        <span *ngIf="textDirectedToCustomer"
          >Du samtykke til at videregive dine oplysninger til Energi Fyn.<span
            class="text-red-500"
            >*</span
          ></span
        >
        <span *ngIf="!textDirectedToCustomer">
          Forhandleren bekræfter at have modtaget kundens samtykke til at
          videregive kundens oplysninger til Energi Fyn.<span
            class="text-red-500"
            >*</span
          ></span
        >
      </p></label
    >
  </div>
</div>

<div class="flex mt-4">
  <input
    [(ngModel)]="helvetiaAcceptConditions.acceptedAsgMarketing"
    type="checkbox"
    class="shrink-0 border-gray-200 focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7] rounded"
    id="acceptedAsgMarketing"
  />
  <label for="acceptedAsgMarketing">
    <p class="ml-4 text-sm">
      <span *ngIf="textDirectedToCustomer">
        Ja tak, jeg vil gerne modtage nyhedsbrev fra Autosource Group-koncernen,
        hvor jeg får nyheder, tips og gode råd via mail. Samtidig giver jeg
        samtykke til, at de oplyste personoplysninger må opbevares og anvendes
        til dette formål. Du kan altid tilbagekalde sit samtykke direkte i
        nyhedsbrevet eller ved at skrive til marketing&#64;autosourcegroup.dk.
        Du kan læse mere om, hvordan vi behandler dine personlige data på
        <a
          class="underline"
          target="_blank"
          href="https://asgcare.dk/persondatapolitik"
          >dette link.</a
        ></span
      >
      <span *ngIf="!textDirectedToCustomer">
        Ja tak, kunden vil gerne modtage nyhedsbrev fra Autosource
        Group-koncernen, hvor kunden får nyheder, tips og gode råd via mail.
        Samtidig giver kunden samtykke til, at kundens oplyste personoplysninger
        må opbevares og anvendes til dette formål. Kunden kan altid tilbagekalde
        sit samtykke direkte i nyhedsbrevet eller ved at skrive til
        marketing&#64;autosourcegroup.dk. Kunden kan læse mere om, hvordan vi
        behandler dine personlige data på
        <a
          class="underline"
          target="_blank"
          href="https://asgcare.dk/persondatapolitik"
          >dette link.</a
        ></span
      >
    </p></label
  >
</div>

<div class="my-4">
  <span class="ml-8 text-sm"
    ><span class="text-red-500">*</span> = påkrævet</span
  >
</div>

<div class="mt-10">
  <button
    *ngIf="!applyForInsuranceLoading"
    (click)="applyForHelvetia()"
    class="py-3 px-4 shadow-lg font-syne inline-flex items-center gap-x-1 text-sm font-semibold rounded-full border border-transparent bg-[#34C0A7] text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
  >
    Bliv forsikret nu
  </button>
  <div *ngIf="applyForInsuranceLoading">
    <svg
      aria-hidden="true"
      class="w-10 h-10 text-gray-100 animate-spin fill-[#1FAD96] m-5"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  </div>
</div>
