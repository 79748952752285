import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DmrResponse, DmrService } from 'projects/shared/src/public-api';

@Component({
  selector: 'lib-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss'],
})
export class VehicleInfoComponent {
  // Event emitted to the parent component
  @Output() setVehicleFromDmr = new EventEmitter<DmrResponse>();
  @Output() setregOrVinNumber = new EventEmitter<string>();
  @Output() nextStepEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();

  // Input properties to receive data from the parent component
  @Input() loadingButton: boolean = false;
  @Input() onlyAllowRegNumber: boolean = false;
  @Input() label: string = '';
  @Input() placeholderText: string = '';

  specialCarCheck = (): boolean => {
    const specialCarsBrands = [
      'Aston Martin',
      'Bentley',
      'Bugatti',
      'Caterham',
      'Ferrari',
      'Hummer',
      'Koenigsegg',
      'Lamborghini',
      'Lotus',
      'Maserati',
      'McLaren',
      'Pagani',
      'Rolls Royce',
      'TVR',
      'Weissmann',
      'Westfield',
    ];

    const isSpecialMake = specialCarsBrands.includes(this.vehicleFromDmr.make);
    const isAllowedCode = [1, 20, 40, 41, 42].includes(
      this.vehicleFromDmr.code
    );

    // Return true if make is special OR code is NOT allowed
    return isSpecialMake || !isAllowedCode;
  };

  vehicleFromDmr: DmrResponse = {} as DmrResponse;
  regOrVinNumber: string = '';

  errorInRegOrVinNumber: boolean = false;
  receivedDMRVehicle: boolean = false;
  regOrVinNumberInputFocused: boolean = false;
  specialCarError: boolean = false;
  loadingRegOrVinNumber: boolean = false;

  constructor(private dmrService: DmrService) {}

  regOrVinNumberChange(): void {
    this.receivedDMRVehicle = false;
    this.setregOrVinNumber.emit(this.regOrVinNumber);
  }

  /**
   * Scraping request for vehicle information based on the reg or VIN number.
   * @returns void
   */
  dmrScrape(): void {
    this.receivedDMRVehicle = false;
    this.specialCarError = false;
    this.errorInRegOrVinNumber = false;

    this.regOrVinNumber = this.regOrVinNumber.replace(/\s/g, '');
    if (this.onlyAllowRegNumber) {
      this.loadingRegOrVinNumber = true;
      // Handle registration number when only registration numbers are allowed
      this.dmrService.scrapeVehicleByRegNumber(this.regOrVinNumber).subscribe({
        next: (result: any) => {
          this.setDmrResult(result.vehicle);
          this.receivedDMRVehicle = true;
          if (this.specialCarCheck()) {
            this.receivedDMRVehicle = false;
            this.specialCarError = true;
          }
        },
        error: (error) => {
          this.loadingRegOrVinNumber = false;
          console.error(error);
        },
      });
    } else {
      // When onlyAllowRegNumber is false or input might be VIN
      if (this.regOrVinNumber.trim().length < 10) {
        this.loadingRegOrVinNumber = true;
        // Attempt to handle as a registration number
        this.dmrService
          .scrapeVehicleByRegNumber(this.regOrVinNumber)
          .subscribe({
            next: (result: any) => {
              this.setDmrResult(result.vehicle);
              this.receivedDMRVehicle = true;
              if (this.specialCarCheck()) {
                this.receivedDMRVehicle = false;
                this.specialCarError = true;
              }
            },
            error: (error) => {
              this.loadingRegOrVinNumber = false;
              console.error(error);
            },
          });
      }

      if (this.regOrVinNumber.trim().length >= 10) {
        this.loadingRegOrVinNumber = true;
        // Attempt to handle as a VIN number
        this.dmrService
          .scrapeVehicleByVINNumber(this.regOrVinNumber)
          .subscribe({
            next: (result: any) => {
              this.setDmrResult(result.vehicleInfo);
              this.receivedDMRVehicle = true;
              if (this.specialCarCheck()) {
                this.receivedDMRVehicle = false;
                this.specialCarError = true;
              }
            },
            error: (error) => {
              this.loadingRegOrVinNumber = false;
              console.error(error);
            },
          });
      }
    }
  }

  /**
   * Checks if the engine volume is in liters instead of cubic centimeters (cm³).
   * If the value is less than 10, it assumes the input is in liters and converts it to cm³.
   * Updates the `engineVolume` property in `vehicleFromDmr` accordingly.
   *
   * @returns void
   */
  checkIfEngineVolume(): void {
    const engineVolume = this.vehicleFromDmr.engineVolume;

    if (engineVolume < 10) {
      // Convert liters to cubic centimeters (cm³)
      const engineVolumeInCm3 = engineVolume * 1000;
      this.vehicleFromDmr.engineVolume = engineVolumeInCm3;
    }
  }

  setDmrResult(vehicle: any) {
    this.vehicleFromDmr = vehicle;
    this.checkIfEngineVolume();
    this.setVehicleFromDmr.emit(this.vehicleFromDmr);
    this.loadingRegOrVinNumber = false;
  }

  /**
   * Get vehicle information from dmrService based on reg or VIN number
   * @returns void
   */
  dmrInfo(): void {
    // Reset all relevant information when this method is called.
    // This is useful if the user has already searched for a car, entered basic information, and then decided to go back.
    this.resetEvent.emit();
    this.setregOrVinNumber.emit(this.regOrVinNumber);

    this.receivedDMRVehicle = false;
    this.specialCarError = false;
    this.errorInRegOrVinNumber = false;
    this.regOrVinNumber = this.regOrVinNumber.replace(/\s/g, '');

    // Handle registration number
    if (this.onlyAllowRegNumber) {
      // Handle registration number when only registration numbers are allowed
      this.loadingRegOrVinNumber = true;
      this.dmrService.getVehicleByRegNumber(this.regOrVinNumber).subscribe({
        next: (result: any) => {
          this.setDmrResult(result.vehicle);
          if (this.specialCarCheck()) {
            this.receivedDMRVehicle = false;
            this.specialCarError = true;
          } else {
            this.receivedDMRVehicle = true;
          }
        },
        error: (error) => {
          this.errorInRegOrVinNumber = true;
          this.loadingRegOrVinNumber = false;
          this.receivedDMRVehicle = false;
          console.error(error);
        },
      });
    } else {
      // When onlyAllowRegNumber is false or input might be VIN
      if (this.regOrVinNumber.trim().length < 10) {
        // Attempt to handle as a registration number
        this.loadingRegOrVinNumber = true;
        this.dmrService.getVehicleByRegNumber(this.regOrVinNumber).subscribe({
          next: (result: any) => {
            this.setDmrResult(result.vehicle);
            if (this.specialCarCheck()) {
              this.receivedDMRVehicle = false;
              this.specialCarError = true;
            } else {
              this.receivedDMRVehicle = true;
            }
          },
          error: (error) => {
            this.errorInRegOrVinNumber = true;
            this.loadingRegOrVinNumber = false;
            this.receivedDMRVehicle = false;
            console.error(error);
          },
        });
      }

      if (this.regOrVinNumber.trim().length >= 10) {
        // Attempt to handle as a VIN number
        this.loadingRegOrVinNumber = true;
        this.dmrService.getVehicleByVINNumber(this.regOrVinNumber).subscribe({
          next: (result: any) => {
            this.setDmrResult(result.vehicleInfo);
            if (this.specialCarCheck()) {
              this.receivedDMRVehicle = false;
              this.specialCarError = true;
            } else {
              this.receivedDMRVehicle = true;
            }
          },
          error: (error) => {
            this.errorInRegOrVinNumber = true;
            this.loadingRegOrVinNumber = false;
            this.receivedDMRVehicle = false;
            console.error(error);
          },
        });
      }
    }
  }

  /**
   * Checks if a given car brand is considered a special brand.
   * @param brand - The car brand name to check.
   * @returns boolean - Returns `true` if the brand is in the special car brands list, otherwise `false`.
   */
  /*specialCarCheck(brand: string): boolean {
    const specialCarsBrands = [
      'ASTON MARTIN',
      'ALPINA',
      'BENTLEY',
      'BUGATTI',
      'HUMMER',
      'LAMBORGHINI',
      'MCLAREN',
      'ROLLS-ROYCE',
      'DE TOMASO',
      'KOENIGSEGG',
      'PAGANI',
      'ZENVO',
      'RUF',
      'RIMAC',
      'FERRARI',
      'LOTUS',
    ];

    return specialCarsBrands.includes(brand);
  }*/

  /**
   * Emit event to move to the next step
   * @returns void
   */
  nextStep(): void {
    this.nextStepEvent.emit();
  }
}
